@font-face {
  font-family: "Modern Sans";
  src: local("Modern Sans Light"),
    url(./ModernSans-Light.otf) format("opentype");
}

@font-face {
  font-family: "Sweet Purple";
  src: local("Sweet Purple"), url(./sweet-purple.otf) format("opentype");
}

h1,
h2,
h3 {
  font-family: "Sweet Purple";
  font-weight: lighter;
}

h1 {
  font-size: 2.75rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.8rem;
  margin: 0;
}

p {
  font-family: "Modern Sans";
  font-weight: lighter;
}
