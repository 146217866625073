@import "./fonts/fonts.scss";
@import "./colours.scss";

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
}

.page-wrapper {
  min-height: 80vh;
  width: 100%;
  overflow: auto;
}

div,
section {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.title-banner {
  background: $brandGreen;
  color: #fff;
  padding: 16px 8px;
  margin: 16px 0;
  text-align: center;

  h1 {
    font-size: 3rem;
    margin: 0;
  }

  a,
  p {
    color: #fff;
    font-size: 1.5rem;
  }
}

.important-disclaimer {
  background-color: $brandPurple;
  color: #fff;
  padding: 8px 16px;
  width: 100%;
  margin-top: 24px;
  text-align: center;

  p {
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
  }
}

.festival-info-page {
  .top-content {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 960px;
    margin: 16px auto;

    img,
    section {
      width: 100%;
    }

    img {
      align-self: flex-start;
    }

    section {
      background: $brandGreen;
      color: #fff;
      padding: 0 16px;
      z-index: 11;
      opacity: 0.9;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      img {
        width: 25px;
        height: 25px;
      }

      a {
        color: #fff;
        font-family: "Modern Sans";
      }
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 960px;
    margin: 16px auto;

    div {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 16px 0;

      img {
        align-self: center;
        width: 200px;
        margin: 16px 0;
      }

      section {
        width: 100%;
        background: $brandPurple;
        color: #fff;
        padding: 16px;

        img {
          width: 20px;
          height: 20px;
          margin: 0 4px;
        }

        a {
          color: #fff;
          font-family: "Modern Sans";
        }
      }
    }
  }
}

.react-cookie-law-dialog {
  top: unset !important;
  bottom: 0;
}

.generic-error {
  width: 100%;
  text-align: center;
  padding: 0 16px;

  p {
    text-transform: uppercase;
    font-size: 20px;
  }
}

@media screen and (min-width: 768px) {
  .festival-info-page {
    .top-content {
      flex-direction: row;

      img,
      section {
        width: 50%;
      }
    }

    .content-wrapper {
      div {
        flex-direction: row;

        section {
          width: 75%;
        }

        img {
          margin: 0;
          margin-right: 24px;
        }
      }
    }
  }
}
