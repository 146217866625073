@import "../../../styles/colours.scss";

footer {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  background-color: $brandGreen;
  color: #fff;
  padding: 0 24px 24px 24px;

  section {
    width: 100%;
    text-align: center;

    a {
      font-family: "Modern Sans";
      font-weight: lighter;
      color: #fff;
      text-decoration: none;
      font-size: 1.2rem;
    }

    a:hover {
      text-decoration: none;
    }
  }

  .page-links {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: column;
    padding-top: 32px;

    div {
      width: 100%;
      margin: 16px 0;
    }

    img {
      width: 35px;
    }
  }
}

@media screen and (min-width: 768px) {
  footer {
    flex-direction: row;

    section {
      width: 50%;
    }

    .page-links {
      flex-direction: row;
    }

    .page-links {
      width: 400px;

      div {
        width: 150px;
        margin: 16px 8px;
      }
    }
  }
}
