@import "../../../styles/colours.scss";

.navbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 120px;
  font-weight: bold;
  text-align: center;
  background-color: $brandPurple;

  a {
    font-family: "Modern Sans";
    font-weight: lighter;
    color: #fff;
    text-decoration: none;
    font-size: 1.3rem;
  }

  a:hover,
  .selected {
    color: rgba(255, 255, 255, 0.5);
  }

  .nav-brand {
    display: flex;
    font-family: "Sweet Purple";
    margin-left: 16px;

    span {
      display: none;
      align-self: center;
      font-size: 2.8rem;
      margin-left: 16px;
    }

    img {
      height: 80px;
    }
  }
}

.main-nav {
  list-style-type: none;
  display: none;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  margin-right: 16px;

  li {
    text-align: center;
    margin: 16px auto;
  }
}

.navbar-toggle {
  position: absolute;
  top: 10px;
  right: 20px;

  img {
    height: 40px;
    margin-top: 30px;
  }
}

.active {
  display: block;
}

.mobile-nav {
  background: $brandPurple;
  position: absolute;
  top: 100px;
  width: 100%;
  z-index: 3;
}

@media screen and (min-width: 425px) {
  .navbar {
    .nav-brand {
      span {
        display: block;
        font-size: 2rem;
      }

      img {
        height: 60px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .navbar {
    .nav-brand {
      span {
        font-size: 2.6rem;
      }

      img {
        height: 80px;
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .navbar {
    a {
      font-size: 1.2rem;
    }

    .nav-brand {
      span {
        font-size: 2.6rem;
      }
    }
  }

  .main-nav {
    display: flex;

    li {
      margin: 0 16px;
    }
  }

  .navbar-toggle {
    display: none;
  }
}

.banner {
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  text-align: center;
  background-color: $brandGreen;
  color: #fff;
  font-family: "modern sans";
  font-size: 1.25rem;

  p {
    margin: 8px 0;

    a {
      color: #fff;

      :hover {
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .navbar {
    a {
      font-size: 1.4rem;
    }

    .nav-brand {
      font-size: 3rem;
    }
  }
}
