.boot-print-wrapper {
  display: none;
  position: absolute;
  opacity: 0.5;
  z-index: 10;
  width: 100%;

  div {
    float: left;

    img:nth-of-type(2) {
      float: right;
    }
  }

  img {
    width: 100px;
  }

  #footGroup1 {
    position: absolute;
    top: 0;
    left: 16px;
    width: 150px;
  }

  #footGroup2 {
    position: absolute;
    top: 32px;
    right: 16px;
    width: 150px;
  }
}

@media screen and (min-width: 768px) {
  .boot-print-wrapper {
    display: block;
  }
}

@media screen and (min-width: 960px) {
  .boot-print-wrapper {
    img {
      width: 140px;
    }

    #footGroup1 {
      top: 0;
      left: 64px;
      width: 200px;
    }

    #footGroup2 {
      top: 32px;
      right: 64px;
      width: 200px;
    }
  }
}
